import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import $ from 'jquery';
import logobox from '../assets/images/white-loader.png';
import favicon from '../assets/images/favicon.png';
import Header from '../component/Header';
import Footer from '../component/Footer';
import axios from 'axios';
import { api_url } from '../Config';
class Home extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
        }
    }
    componentDidMount() {
      
  // $(window).load(function() {
    $(".loader").delay(3400).fadeOut("slow");
  $("#overlayer").delay(3400).fadeOut("slow");
// });
// $(window).load(function() {
    $(".loader").delay(3400).fadeOut("slow");
  $("#overlayer").delay(3400).fadeOut("slow");
// });


    }

    render() {

        return (
            <React.Fragment>
                <body className="login_Bg">
                <div className="overlay_body"></div>

              <span className="loader loder_black">
              <div className="logobox">
              <img  src={logobox} alt="" />
              </div>
          </span>
                   <section className="top_bar">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-3 col-md-3 col-sm-3 pd-0 menubox">
                         
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-3 pd-0 main_head">
                              <h4>Order Express</h4>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-3 pd-0 logo_box">
                              <a href="#"><img  src={favicon} alt="" /></a>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="login_box">
                      <div className="main_wrap login_page">
                      <a hred="#"><img  src={favicon} alt="" /></a>
                        <h3>Rider Login</h3>
                        <p>Please enter the requested information.</p>
                        <form action="" method="">
                          <div className="form_box">
                              <span className="lnr lnr-envelope"></span>
                              <input type="email" placeholder="Username or Email Adress " required="required" name="email" />
                          </div>
                          <div className="form_box">
                              <span className="lnr lnr-lock"></span>
                              <input type="password" placeholder="Password" required="required" name="password" className="" />
                          </div>
                          <div className="form_box">
                              <a href="/dashboard"><button type="button" className="login_btn">Sign In</button></a>
                          </div>
                        </form>
                      </div>
                    </section>
                </body>


               
            </React.Fragment>
        )
    }
}
export default Home;
