import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import $ from 'jquery';
import favicon from '../assets/images/favicon.png';
import mapmarker from '../assets/images/map-marker.png';
import Header from '../component/Header';
import Footer from '../component/Footer';
import axios from 'axios';
import { api_url } from '../Config';
class MyRoute extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
        }
    }
    componentDidMount() {
      
    }

    render() {

        return (
            <React.Fragment>
                <body className="login_Bg">
                      <Header title="My Route" tagline="" />
                      <section className="login_box">
                        <div className="searchbar myroute_search">
                          <input type="text" placeholder="Search..." name="" />
                          <span className="lnr lnr-magnifier"></span>
                        </div>
                        <div className="route_map myroute_box">
                            {<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.37079827899!2d74.3411742146307!3d31.513974354612216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904597246cb3b%3A0xf0354bab8f69b122!2sAl%20Hafeez%20Shopping%20Mall%2C%20321%20Main%20Blvd%20Gulberg%2C%20Block%20D1%20Block%20D%201%20Gulberg%20III%2C%20Lahore%2C%20Punjab%2054660%2C%20Pakistan!5e0!3m2!1sen!2s!4v1625678763806!5m2!1sen!2s" style={{border:'0'}} allowfullscreen="" loading="lazy"></iframe>}
                          </div>
                        
                      </section>
                      <div className="icon_order">
                            <i className="lnr lnr-cart"></i>
                           
                          </div>

                        <div className="order_list_sidebar">
                          <div className="order_inner">
                            <h4>Orders</h4>
                            <ul>
                              <li><b>#10043</b> (Gulberg)</li>
                              <li><b>#10044</b> (DHA Phase 2)</li>
                              <li><b>#10045</b> (DHA Phase 3)</li>
                              <li><b>#10046</b> (DHA Phase 5)</li>
                              <li><b>#10047</b> (Garden Town)</li>
                              <li><b>#10048</b> (Johar Town)</li>
                            </ul>
                          </div>
                          <div className="icon_order_hide">
                           <i className="lnr lnr-cross"></i>
                          </div>
                         
                        </div>

                        <div className="map_marker_lctn">
                          <a href="#">
                          <img  src={mapmarker} alt="" />
                          </a>
                        </div>
                </body>


                <Footer />
            </React.Fragment>
        )
    }
}
export default MyRoute;
