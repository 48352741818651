import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import $ from 'jquery';
import favicon from '../assets/images/favicon.png';
import Header from '../component/Header';
import Footer from '../component/Footer';
import axios from 'axios';
import { api_url } from '../Config';
class DeliveredOrders extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
        }
    }
    componentDidMount() {
      
    }

    render() {

        return (
            <React.Fragment>
                <body className="login_Bg">
                      <Header title="Delivered Orders" tagline="" />
                      <section className="login_box listing_view" id="height_box">
         <div className="main_wrap dashboard_box">
            <div className="searchbar">
               <input type="text" placeholder="Search..." name="" />
               <span className="lnr lnr-magnifier"></span>
            </div>
            <div className="assigned__order">
               <div className="inner_assigned">
                  <a href="/orderdetails">
                     <p className="orderno">#10034 <span>1:43PM</span> <span>10-07-2021</span></p>
                     <p className="numberbox"><i className="lnr lnr-user"></i> Ahmad Ali - <span ><i className="lnr lnr-phone-handset"></i> 
                  <a href="tel:03324510131">03324510131</a></span></p>
                  <p className="address"><i className="lnr lnr-map-marker"></i> Al Hafeez Shopping Mall, Gulberg 3, Lahore</p>
                  </a>
               </div>
            </div>
            <div className="assigned__order">
               <div className="inner_assigned">
                  <a href="/orderdetails">
                     <p className="orderno">#10044 <span>1:43PM</span> <span>10-07-2021</span></p>
                     <p className="numberbox"><i className="lnr lnr-user"></i> Asad khalid - <span ><i className="lnr lnr-phone-handset"></i> 
                  <a href="tel:03324510131">03324510131</a></span></p>
                  <p className="address"><i className="lnr lnr-map-marker"></i> Al Hafeez Shopping Mall, Gulberg 3, Lahore</p>
                  </a>
               </div>
            </div>
            <div className="assigned__order">
               <div className="inner_assigned">
                  <a href="/orderdetails">
                     <p className="orderno">#10055 <span>1:43PM</span> <span>10-07-2021</span></p>
                     <p className="numberbox"><i className="lnr lnr-user"></i> Fakher Abbass - <span ><i className="lnr lnr-phone-handset"></i> 
                  <a href="tel:03324510131">03324510131</a></span></p>
                  <p className="address"><i className="lnr lnr-map-marker"></i> Al Hafeez Shopping Mall, Gulberg 3, Lahore</p>
                  </a>
               </div>
            </div>
            <div className="assigned__order">
               <div className="inner_assigned">
                  <a href="/orderdetails">
                     <p className="orderno">#10066 <span>1:43PM</span> <span>10-07-2021</span></p>
                     <p className="numberbox"><i className="lnr lnr-user"></i>  Ahmad But - <span ><i className="lnr lnr-phone-handset"></i> 
                  <a href="tel:03324510131">03324510131</a></span></p>
                  <p className="address"><i className="lnr lnr-map-marker"></i> Al Hafeez Shopping Mall, Gulberg 3, Lahore</p>
                  </a>
               </div>
            </div>
            <div className="assigned__order">
               <div className="inner_assigned">
                  <a href="/orderdetails">
                     <p className="orderno">#10044 <span>1:43PM</span> <span>10-07-2021</span></p>
                     <p className="numberbox"><i className="lnr lnr-user"></i> Asad khalid - <span ><i className="lnr lnr-phone-handset"></i> 
                  <a href="tel:03324510131">03324510131</a></span></p>
                  <p className="address"><i className="lnr lnr-map-marker"></i> Al Hafeez Shopping Mall, Gulberg 3, Lahore</p>
                  </a>
               </div>
            </div>
            <div className="assigned__order">
               <div className="inner_assigned">
                  <a href="/orderdetails">
                     <p className="orderno">#10055 <span>1:43PM</span> <span>10-07-2021</span></p>
                     <p className="numberbox"><i className="lnr lnr-user"></i> Fakher Abbass - <span ><i className="lnr lnr-phone-handset"></i> 
                  <a href="tel:03324510131">03324510131</a></span></p>
                  <p className="address"><i className="lnr lnr-map-marker"></i> Al Hafeez Shopping Mall, Gulberg 3, Lahore</p>
                  </a>
               </div>
            </div>
            <div className="assigned__order">
               <div className="inner_assigned">
                  <a href="/orderdetails">
                     <p className="orderno">#10066 <span>1:43PM</span> <span>10-07-2021</span></p>
                     <p className="numberbox"><i className="lnr lnr-user"></i>  Ahmad But - <span ><i className="lnr lnr-phone-handset"></i> 
                  <a href="tel:03324510131">03324510131</a></span></p>
                  <p className="address"><i className="lnr lnr-map-marker"></i> Al Hafeez Shopping Mall, Gulberg 3, Lahore</p>
                  </a>
               </div>
            </div>
            <div className="assigned__order">
               <div className="inner_assigned">
                  <a href="/orderdetails">
                     <p className="orderno">#10055 <span>1:43PM</span> <span>10-07-2021</span></p>
                     <p className="numberbox"><i className="lnr lnr-user"></i> Fakher Abbass - <span ><i className="lnr lnr-phone-handset"></i> 
                  <a href="tel:03324510131">03324510131</a></span></p>
                  <p className="address"><i className="lnr lnr-map-marker"></i> Al Hafeez Shopping Mall, Gulberg 3, Lahore</p>
                  </a>
               </div>
            </div>
            <div className="assigned__order">
               <div className="inner_assigned">
                  <a href="/orderdetails">
                     <p className="orderno">#10066 <span>1:43PM</span> <span>10-07-2021</span></p>
                     <p className="numberbox"><i className="lnr lnr-user"></i>  Ahmad But - <span ><i className="lnr lnr-phone-handset"></i> 
                  <a href="tel:03324510131">03324510131</a></span></p>
                  <p className="address"><i className="lnr lnr-map-marker"></i> Al Hafeez Shopping Mall, Gulberg 3, Lahore</p>
                  </a>
               </div>
            </div>
         </div>
      </section>
                </body>


                <Footer />
            </React.Fragment>
        )
    }
}
export default DeliveredOrders;
