import React from 'react';

const Footer = () => {
    return (
       <div class="bottom_bar">
   <ul>
      <li id="home_icon">
         <a href="/dashboard">
            <span class="lnr lnr-home"></span>
            <b>Dashboard</b>
         </a>
      </li>
      <li>
         <a href="/myroute">
            <span class="lnr lnr-map-marker"></span>
            <b>My Route</b>
         </a>
      </li>
      <li>
         <a href="/profile">
            <span class="lnr lnr-user"></span>
            <b>Profile</b>
         </a>
      </li>
   </ul>
</div>
    )
}

export default Footer
