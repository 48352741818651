import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import $ from 'jquery';
import favicon from '../assets/images/favicon.png';
import mapmarker from '../assets/images/map-marker.png';
import checkbox from '../assets/images/checkbox-icon.png';
import Header from '../component/Header';
import Footer from '../component/Footer';
import axios from 'axios';
import { api_url } from '../Config';
class OrderDetails extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
        }
    }
    componentDidMount() {
      
    }

    render() {

        return (
            <React.Fragment>
                <body className="login_Bg">
                      <Header title="Order Details" tagline="" />
                      <section className="login_box">
                        <div className="order_details searchbar myroute_search" id="order_details_table">
                          <div className="info_order">
                            <h4>Customer Information</h4>
                            <table className="table">
                              <tr>
                                <th>Name</th>
                                <td>Ahmad Khan</td>
                              </tr>
                              <tr>
                                <th>Phone</th>
                                <td style={{fontSize:'14px'}}><a href="tel:+923028639192" className="phone_tel">+923028639192</a></td>
                              </tr>
                              <tr>
                                <th>Shipping Address</th>
                                <td className="Address_icon"><a href="#">Plot 119, DHA Sector EE Dha Phase ,Lahore, Punjab <i className="fa fa-map-marker"></i></a></td>
                              </tr>
                          </table>
                          </div>

                          <div className="info_order">
                            <h4>Order Information</h4>
                            <table className="table">
                              <tr>
                                <th>Order  Code</th>
                                <td>2107095756</td>
                              </tr>
                              <tr>
                                <th>Order Date</th>
                                <td>09-07-2021 11:15 AM</td>
                              </tr>
                              <tr>
                                <th> Time Selected</th>
                                <td style={{fontSize:'11px'}} >Fri-09-jul (11:00 am-1:30 pm)</td>
                              </tr>
                              <tr>
                                <th>Order Status</th>
                                <td>Pending</td>
                              </tr>
                              <tr>
                                <th>Total  amount</th>
                                <td style={{fontWeight:'700',fontSize:'17px',color:'#591845'}}>Rs:722.00</td>
                              </tr>
                              <tr>
                                <th>Payment Method</th>
                                <td>Cash on delivery</td>
                              </tr>
                          </table>
                          </div>

                          <div className="info_order">
                            <h4>Order Details</h4>
                            <table className="table">
                              <tr>
                                <th>Product</th>
                                <td>Dalda 1kg</td>
                              </tr>
                              <tr>
                                <th>Quantity</th>
                                <td>20</td>
                              </tr>
                              <tr>
                                <th>Price</th>
                                <td>Rs:296.00</td>
                              </tr>
                          </table>
                          </div>


                        </div>
                      </section>

                      <div className="delivery_now">
                        <a href="#">Deliver Now</a>
                      </div>

                      <div className="overlay_order"></div>
                      <div className="overlay_order_info">
                        <div className="success">
                        <img  src={checkbox} alt="" />
                          <h3>Order Delivered Successfully</h3>
                        </div>
                      </div>
                </body>


                <Footer />
            </React.Fragment>
        )
    }
}
export default OrderDetails;
