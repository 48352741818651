import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import $ from 'jquery';
import favicon from '../assets/images/favicon.png';
import mapmarker from '../assets/images/map-marker.png';
import checkbox from '../assets/images/checkbox-icon.png';
import Header from '../component/Header';
import Footer from '../component/Footer';
import axios from 'axios';
import { api_url } from '../Config';
class Profile extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
        }
    }
    componentDidMount() {
      
// profile

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').css('background-image', 'url('+e.target.result +')');
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function() {
    readURL(this);
});
    }

    render() {

        return (
            <React.Fragment>
                <body className="login_Bg">
                      <Header title="Assigned Orders" tagline="" />
                      <section class="login_box user_registration">
                      <div class="main_wrap">
                        <form action="" method="">
                          <div class="avatar-upload">
                            <div class="avatar-edit">
                                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                                <label for="imageUpload"></label>
                            </div>
                            <div class="avatar-preview">
                                <div id="imagePreview" >
                                </div>
                            </div>
                        </div>
                          <div class="form_box">
                              <span class="lnr lnr-user"></span>
                              <input type="text" placeholder="Name" required="required" name="name" />
                          </div>
                          <div class="form_box">
                              <span class="lnr lnr-phone-handset"></span>
                              <input type="text" placeholder="Phone No" required="required" name="phone"/>
                          </div>
                          <div class="form_box">
                              <span class="lnr lnr-envelope"></span>
                              <input type="text" placeholder="Email" required="required" name="email"/>
                          </div>
                          <div class="form_box">
                              <span class="lnr lnr-lock"></span>
                              <input type="password" placeholder="Password" required="required" name="password" class=""/>
                          </div>
                          <div class="form_box">
                              <span class="lnr lnr-lock"></span>
                              <input type="password" placeholder="Repeat Password" required="required" name="Repeat password" class=""/>
                          </div>
                          <div class="form_box">
                              <button type="submit" class="login_btn">Submit</button>
                          </div>
                        </form>
                        
                      </div>
                    </section>
                </body>

            </React.Fragment>
        )
    }
}
export default Profile;
