import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Home from './container/Home.js';
import Dashboard from './container/Dashboard.js';
import AssignedOrders from './container/AssignedOrders.js';
import DeliveredOrders from './container/DeliveredOrders.js';
import MyRoute from './container/MyRoute.js';
import OrderDetails from './container/OrderDetails.js';
import Profile from './container/Profile.js';
// import NoPage from './container/NoPage';
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/assignedorder" component={AssignedOrders} />
        <Route exact path="/deliveredorder" component={DeliveredOrders} />
        <Route exact path="/myroute" component={MyRoute} />
        <Route exact path="/orderdetails" component={OrderDetails} />
        <Route exact path="/profile" component={Profile} />
      </Switch>
    </Router>
  );
}

export default App;
